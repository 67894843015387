import React, { memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Skeleton } from '../../Skeleton';

const MedwikiLandingFeaturedLoader = () => {
  return (
    <>
      <Swiper
        className={`medwikiFeaturedSlide shadow rounded-3 bg-white w-100 position-relative`}
        slidesPerView={1}
        loop={false}
        pagination={{ clickable: true }}
        effect={'fade'}
        id="medwiki_landing_feature_card"
      >
        {[1, 2].map((i, index) => (
          <SwiperSlide
            className="medwikiFeaturedMain d-flex flex-wrap bg-white h-auto p-3 pb-50 p-md-4 pb-md-4"
            key={index + 1}
          >
            <div className="medwikiFeaturedPic rounded-3 position-relative overflow-hidden mb-20 mb-md-0">
              <Skeleton
                variant="rectangular"
                width="100%"
                height={200}
                className="object-fit-cover w-100 h-100 float-start"
              />
            </div>
            <div className="d-flex medwikiFeaturedRight flex-column justify-content-center text-start position-relative z-1 p-0 ps-0 py-md-3 ps-md-20 ps-xxl-5">
              <div className="rounded-3 bg-white shadow medwikiFeaturedContent p-0 p-md-20 py-lg-20 px-lg-5 position-relative">
                <div className="d-flex align-items-center mb-3 justify-content-between">
                  <span className="text-black d-inline-block fs-4 cursorPointer mb-3 gtm_cl_medwiki_landing_featured_speciality">
                    <Skeleton variant="text" width={100} height={15} />
                  </span>
                  <Skeleton variant="circular" width={20} height={20} />
                </div>

                {/* <h2 className="line-clamp-2 fs-16 text-black mb-3 gtm_cl_medwiki_landing_feature_card">
                  <Skeleton variant="text" width="80%" height={25} />
                </h2> */}
                <h5 className="line-clamp-2 fs-4 text-dark mb-4 gtm_cl_medwiki_landing_feature_card mt-2">
                  <Skeleton variant="text" width="100%" height={15} />
                  <Skeleton variant="text" width="90%" height={15} />
                </h5>
                <div className="d-flex align-items-center justify-content-end">
                  <Skeleton variant="rectangular" className='rounded' width={120} height={35} />
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default memo(MedwikiLandingFeaturedLoader);
