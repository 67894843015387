import React, { useState } from 'react';

import { Button, Card, Col, Row } from 'react-bootstrap';
import EventCalendar from './components/EventCalendar';
import SpecialitiesPill from '../../components/SpecialitiesPill';
import { CgArrowLongRight, BsThreeDots } from '../../components/icons';
import ShareCard from '../../components/CustomLibraries/videoPlayerV1/components/ShareCard';
import useRedirect from '../../common/hooks/useRedirect';
import { routeNames } from '../../router/constants';
import { useSessionUpcomingCalender } from '../../Queries/QueryHooks/session.hook';
import { removeHtmlTags } from '../../common/common';
import dayjs from 'dayjs';

function CalendarPage() {
  const { data: calendarData } = useSessionUpcomingCalender();

  const [clickedDate, setClickedDate] = useState([]);
  console.log('calendarData', calendarData);

  return (
    <div>
      <Row className="p-5">
        <Col sm={12} md={9} lg={9} xxl={9}>
          <EventCalendar
            events={
              calendarData
                ? calendarData.map((item) => {
                    return {
                      ...item,
                      start: dayjs(item?.start).format('YYYY-MM-DD'),
                      end: dayjs(item?.end).format('YYYY-MM-DD')
                    };
                  })
                : []
            }
            calendarData={calendarData}
            setClickedDate={setClickedDate}
          />
        </Col>
        <Col sm={12} md={3} lg={3} xxl={3}>
          <h3 className="fs-2 pb-2 fw-semibold text-primary">
            <span className="text-dark">Upcoming</span> Conferences
          </h3>
          <p>
            Join us to network with peers,learn from experts, and stay ahead of the curve in
            healthcare.
          </p>
          {clickedDate.map((event, index) => {
            return Array.isArray(event) ? (
              event.map((ev) => (
                <div key={ev?.id} className="">
                  <EventCard data={ev} />
                </div>
              ))
            ) : (
              <div key={event?.id} className="">
                <EventCard data={event} />
              </div>
            );
          })}
        </Col>
      </Row>
    </div>
  );
}

export default CalendarPage;

const EventCard = ({ data }) => {
  const { redirectTo } = useRedirect();
  console.log('data', data);
  return (
    <Card className="shadow rounded-3 border-0 mb-4">
      <Card.Body>
        <div className="p-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="text-primary bg-primary bg-opacity-10 rounded-2 py-1 px-3 fw-normal fs-5 m-0 lh-base">
              {dayjs(data?.start).format('ddd D MMM | h:mm a')}
            </p>
            {/* <div className="cursorPointer icon-size-16 rotate-90">
              <BsThreeDots />
            </div> */}
          </div>

          <p className="fs-4 fw-medium">{removeHtmlTags(data?.title)}</p>

          <div className="d-flex justify-content-end align-items-center gap-4 mt-3">
            <div className="bg-light bg-opacity-50 p-1 rounded-2">
              <ShareCard
                // gtmTagVariable={`gtm_cl_quizCard_share`}
                data={{
                  // title: data?.survey_title,
                  url: data?.deeplink,
                  is_share: data?.is_share
                }}
              />
            </div>
            <Button
              className="btn btn-primary btn-sm d-flex align-items-center gap-2"
              onClick={() => {
                redirectTo(routeNames.resources.details, `${data?.id}`);
              }}
            >
              Attend
              <CgArrowLongRight className="fs-4" />
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
