import React from 'react';
import LandingFeatureCard from '../../components/herosection/LandingFeatureCard';
import { TrackerComponent } from '../../TrackerFolder';
import Seo from '../../components/seo';
import '../../components/ContentSlider/css/contentSlider.scss';
import ResourcesCategoriesList from './components/ResourcesCategoriesList';
import MedwikiPopular from '../medwikiV2/MedwikiLandingComponents/MedwikiPopular';
import { routeNames } from '../../router/constants';
import useRedirect from '../../common/hooks/useRedirect';
import { useMedwikiFeatured } from '../../Queries/QueryHooks/medwiki.hook';
import { useSessionFeatured, useSessionUpcoming } from '../../Queries/QueryHooks/session.hook';
import { BannerItem } from '../../components/banner';
import { PAGE_VIEW } from '../../TrackerFolder/constants/ActionType';

function ResourcesPage() {
  const { redirectTo } = useRedirect();
  const { data: featured_session_data, isLoading: featuredLoader } = useSessionFeatured(
    () => {},
    () => {},
    'conferrence'
  );
  const { data: popularSessions, isLoading: popularLoader } = useSessionUpcoming(
    () => {},
    () => {},
    'conferrence',
    false
  );

  return (
    <div>
      <div className="w-100 float-start desktopBodyIn">
        <div className="container-fluid">
          <TrackerComponent page_name={'resources'} module_page="resources" type={PAGE_VIEW} />
          {/* <Seo title={`MediSamvad ${pageName}`} url={window.location.href} /> */}
          <div className="w-100 topBanner">
            <BannerItem position={'top'} page_name={'comp'} />
          </div>
          <section className="w-100 cmnPageContent">
            {/* common for all page */}
            <div className="w-100 float-start medwikiLandingV2">
              <div className="medwikiLandingTop mb-4 position-relative d-flex flex-column flex-lg-row justify-content-between">
                <LandingFeatureCard
                  data={
                    featured_session_data && Array.isArray(featured_session_data)
                      ? featured_session_data.map((item) => {
                          return { ...item };
                        })
                      : []
                  }
                  isLoading={featuredLoader}
                  redirectPageName={routeNames.resources.details}
                  page="resources"
                />
                <MedwikiPopular
                  posts={popularSessions?.slice(0, 3)}
                  title={'Upcoming Conferences'}
                  eventDateShow={true}
                  isLoading={popularLoader}
                  onExploreMoreClick={() => redirectTo(routeNames.resources.calendar)}
                  buttonText={'View All'}
                />
              </div>
              <ResourcesCategoriesList />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ResourcesPage;
