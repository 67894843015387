import React from 'react';
import { Card } from 'react-bootstrap';
import { TrophyIcon } from '../../components/icons';
import LazyImage from '../../components/CustomLibraries/LazyImage';
import { useLeaderBoard } from '../../Queries/QueryHooks/session.hook';

function LeaderboardPage() {
  const { data: allUserData, isLoading } = useLeaderBoard();
  const data = allUserData?.all_list;

  return (
    <div className="w-100 float-start desktopBodyIn">
      <Card className="rounded-4 m-2 m-md-0 leaderBoard position-relative overflow-hidden">
        <div className="text-center">
          <h1 className="px-5 py-3 text-white fs-2 d-inline-block bg-danger rounded-4 rounded-top-0 text-uppercase mb-4">
            Leaderboard
          </h1>
        </div>

        <Card.Body className="p-2 p-md-5">
          <div className="row">
            {!isLoading && data?.length > 0 && (
              <>
                <div className="col-lg-4">
                  <RenderCard data={data?.slice(0, 6)} />
                </div>
                <div className="col-lg-4">
                  <RenderCard data={data?.slice(6, 12)} />
                </div>
                <div className="col-lg-4">
                  <RenderCard data={data?.slice(12, 18)} />
                </div>
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default LeaderboardPage;

function RenderCard({ data }) {
  const colors = ['gold', 'silver', 'bronze'];
  return (
    <>
      {data &&
        data?.map((data, index) => {
          return (
            <div
              key={data?.id}
              className="d-flex gap-4 align-items-center justify-content-start px-2 py-4 px-4"
            >
              {index < 3 ? (
                <TrophyIcon color={colors[index]} />
              ) : (
                <span className="fs-3 text-danger">#{index}</span>
              )}
              <div className="icon-size-48 bg-light rounded-2 border border-2">
                <LazyImage
                  src={data?.profile_image}
                  className="object-fit-cover h-100 w-100 float-start"
                />
              </div>
              <div className="fw-semibold lh-1 fs-4">
                {data?.first_name} {data?.middle_name} {data?.last_name}
              </div>
              <div className="ms-auto">
                <b className="fs-4 text-primary">{data?.user_point}</b> Points
              </div>
            </div>
          );
        })}
    </>
  );
}
